"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataTypes = void 0;
var MetadataTypes;
(function (MetadataTypes) {
    MetadataTypes["description"] = "Description";
    MetadataTypes["department"] = "Department";
    MetadataTypes["brand"] = "Brand";
    MetadataTypes["design_cycle"] = "Design Cycle";
    MetadataTypes["division"] = "Division";
    MetadataTypes["group"] = "Group";
    MetadataTypes["sub_group"] = "Subgroup";
    MetadataTypes["PID"] = "PID";
    MetadataTypes["material_id"] = "Material ID";
    MetadataTypes["material_type"] = "Material Type";
    MetadataTypes["vendors"] = "Vendors";
    MetadataTypes["tcin"] = "TCIN";
    MetadataTypes["dpci"] = "DPCI";
    MetadataTypes["class"] = "Class";
    MetadataTypes["launch_date"] = "Launch Date";
    MetadataTypes["product_dimensions"] = "Product Dimensions";
    MetadataTypes["package_dimensions"] = "Package Dimensions";
    MetadataTypes["avg_guest_rating"] = "Avg Guest Rating";
    MetadataTypes["mode_price"] = "Mode Price";
    MetadataTypes["product_materials"] = "Product Materials";
    MetadataTypes["product_color"] = "Product Color";
    MetadataTypes["impressions"] = "Impressions";
    MetadataTypes["category_type"] = "Category Type";
    MetadataTypes["top_selling"] = "Top Selling";
    MetadataTypes["country_of_origin"] = "Country of Origin";
    MetadataTypes["product_cost"] = "Product Cost";
    MetadataTypes["claims"] = "Claims";
    MetadataTypes["vendor_style_number"] = "Vendor Style Number";
    MetadataTypes["SAMPLE_LOCATIONS"] = "Sample Locations";
    MetadataTypes["inclusive_design_attributes"] = "Inclusive Design Attributes";
    MetadataTypes["primary_material"] = "Primary Material";
})(MetadataTypes || (exports.MetadataTypes = MetadataTypes = {}));
